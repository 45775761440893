import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',  
  template: ``,
})
export class FooterComponent {
  get currentYear(): number {
    return new Date().getFullYear();
  }
}
