import { UsuarioPerfil } from './../../enums/usuario-perfil.enum';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    private baseUrl = environment.apiUrl + "/users";

    constructor(public http: HttpClient) { }

    cadastrar(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/cadastrar`, item);
    }

    atualizar(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/atualizar`, item);
    }

    remover(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/excluir`, { id: item.id });
    }

    alterarSenha(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/mudar-password`, item);
    }

    iniciarRecuperacaoSenha(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/iniciar-recuperacao-senha`, item);
    }

    finalizarRecuperacaoSenha(item): Observable<any> {
        return this.http.post(`${this.baseUrl}/finalizar-recuperacao-senha`, item);
    }

    list(filter: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/pesquisar`, filter);
    }

    listByPerfil(perfil: UsuarioPerfil): Observable<any> {
        return this.http.get(`${this.baseUrl}/listar/por-perfil?perfil=${perfil}`);
    }

    get(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    getDashboard(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}/dashboard`);
    }
}
