
<nb-card style="width: 800px;">
    <nb-card-header>{{ titulo }}</nb-card-header>
    <nb-card-body>
        {{ mensagem }}
    </nb-card-body>
    <nb-card-footer>
        <div class="row" style="flex-direction: row-reverse">
            <div class="col-lg-2">
                <button (click)="okClicked()" nbButton status='success' fullWidth>OK</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>
