import { UiService } from '../shared/services/ui.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from 'app/shared/services/http/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private router: Router, private authService: AuthService, private ui: UiService, private toastrService: NbToastrService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        let requestFinal = req.clone();
        if (token) {
            requestFinal = this.addTokenHeader(req, JSON.parse(token).access_token);
        }


        let catchErrorFn = (error: HttpErrorResponse) => {            
            if (error instanceof HttpErrorResponse && !req.url.includes('auth/login-backoffice') && error.status === 401) {
                return this.handle401Error(req, next);
            }

            if (error.status === 422) {
                this.ui.loaded().subscribe(() => {
                    this.toastrService.warning(`${error.error}`, 'Atenção');
                });
                return EMPTY;
            }

            if (error.status === 500) {
                this.ui.loaded().subscribe(() => {
                    this.toastrService.danger(`Ocorreu uma falha inesperada no sistema.`, 'Atenção');
                });
                return EMPTY;
            }

            return throwError(error);
        };
        return next.handle(requestFinal).pipe(catchError(catchErrorFn));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (this.isRefreshing || !localStorage.getItem("token")) {
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap((token) => next.handle(this.addTokenHeader(request, token)))
            );
        }

        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        const currentRefreshToken = JSON.parse(localStorage.getItem("token")).refresh_token;

        return this.authService.loginRefreshToken({ token: currentRefreshToken, extenderSessao: !request.headers.has('X-NaoExtenderSessao') }).pipe(
            switchMap((refreshTokenResponse: any) => {                
                if(!refreshTokenResponse.succeeded) {                    
                    return throwError(new Error("Failed refreshing token"));
                }
                this.isRefreshing = false;
                let token = refreshTokenResponse.data;
                localStorage.setItem('token', JSON.stringify(token));
                localStorage.setItem('refreshToken', token.refresh_token);

                this.refreshTokenSubject.next(token.access_token);
                return next.handle(this.addTokenHeader(request, token.access_token));
            }),
            catchError((err) => {
                this.isRefreshing = false;
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                this.ui.loaded().subscribe(() => { });
                this.router.navigate(['auth/login']);
                return throwError(err);
            })
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ headers: request.headers.set("Authorization", 'Bearer ' + token) });
    }
}
