import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'ngx-logout',
    templateUrl: './logout.page.html',
})
export class LogoutPage implements OnInit {
    constructor(protected router: Router) { }

    ngOnInit(): void {
        this.logout();
    }

    logout(): void {
        setTimeout(() => {
            localStorage.removeItem('token');
            this.router.navigate(['auth/login']);
        }, 1500);
    }
}
