<h4 id="title" class="title text-white">Recuperação de Senha</h4>

<div [formGroup]="requestPasswordForm" aria-labelledby="title">
    <div class="form-control-group">
        <label for="input-email" class="text-white">Login/E-mail:</label>
        <input class="input-user" formControlName="email" [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''" [attr.aria-invalid]="email.invalid && email.dirty ? true : null" nbInput autofocus fullWidth fieldSize="large" id="input-email" />
        <p><a class="forgot-password text-white" routerLink="../login">Voltar para o Log In</a></p>
      </div>

  <button (click)="requestPass()" [disabled]="!requestPasswordForm.valid" nbButton fullWidth status="primary" size="large" style="background-color:#00ccff !important;">          
    Recuperar Senha
  </button>
</div>

<section class="sign-in-or-up" aria-label="Sign in or sign up">

  
</section>

