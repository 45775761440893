import { Component, OnDestroy, OnInit } from '@angular/core';
import { InitUserService } from './@theme/services/init-user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NbIconLibraries } from '@nebular/theme';
import * as MyIcons from 'assets/icons/my-icons';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet> <ngx-spinner bdColor="rgba(0, 0, 0, 0.90)" size="large" color="#6610f2" secondaryColor="#0078BF" type="ball-fussion" [fullScreen]="true"></ngx-spinner>',
})
export class AppComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private initUserService: InitUserService, private iconLibraries: NbIconLibraries) {
    this.initUser();
    this.iconLibraries.registerSvgPack('my-icons', MyIcons);
  }

  ngOnInit(): void {
  }

  initUser() {
    const token = localStorage.getItem('token');
    if(!token) return;
    this.initUserService.initCurrentUser().pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
