import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ngx-confirmacao.dialog',
    templateUrl: './confirmacao.dialog.component.html',
    styleUrls: ['./confirmacao.dialog.component.scss']
})
export class ConfirmacaoDialogComponent implements OnInit {

    @Input()
    titulo: string;

    @Input()
    mensagem: string;

    @Input()
    simCallback;

    @Input()
    naoCallback;

    constructor() { }

    ngOnInit(): void {
    }

    simClicked() {
        if (this.simCallback) {
            this.simCallback();
        }

    }

    naoClicked() {
        if (this.naoCallback) {
            this.naoCallback();
        }
    }
}
