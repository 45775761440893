import { ConfirmacaoDialogComponent } from './../components/confirmacao.dialog/confirmacao.dialog.component';
import { Injectable } from '@angular/core';
import { NbDialogService, NbToastrService, NbDialogRef } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { OKDialogComponent } from '../components/ok.dialog/ok.dialog.component';

@Injectable({
    providedIn: 'root'
})
export class UiService {

    constructor(private spinner: NgxSpinnerService, private toastrService: NbToastrService, private dialogService: NbDialogService) { }

    success(message: string, life: number = 3000) {
        this.toastrService.success(`${message}`, 'Sucesso');
    }

    warning(message: string) {
        this.toastrService.warning(`${message}`, 'Atenção');
    }

    info(message: string) {
        this.toastrService.info(`${message}`, 'Informação');
    }

    error(message: string, sticky: boolean = true) {
        this.toastrService.danger(`${message}`, 'Atenção');
    }

    confirm(message: string, confirmCallback) {
        let dialog;

        let config = {
            titulo: "Confirmação",
            mensagem: message,
            simCallback: () => { 
                if(dialog) {
                    dialog.close();
                }
                confirmCallback();                
            },
            naoCallback: () => { 
                if(dialog) {
                    dialog.close();
                }
            }
        };
        dialog = this.dialogService.open(ConfirmacaoDialogComponent, { context: config });
    }

    ok(message: string, confirmCallback) {
        let dialog;

        let config = {
            titulo: "Confirmação",
            mensagem: message,
            okCallback: () => { 
                if(dialog) {
                    dialog.close();
                }
                confirmCallback();                
            },
        };
        dialog = this.dialogService.open(OKDialogComponent, { context: config });
    }

    loading() {
        this.spinner.show();
    }

    loaded(message?) {
        return Observable.create(observer => {
            setTimeout(() => {
                if (message) {
                    document.querySelector(".la-ball-fussion").classList.add("fade-out");

                    setTimeout(() => {
                        document.querySelector(".la-ball-fussion").textContent = null;
                        document.getElementById("spinnerMessage").textContent = message;
                        document.getElementById("spinnerMessage").classList.add("fade-in");
                    }, 800);

                    setTimeout(() => {
                        this.spinner.hide();
                        observer.next();
                        observer.complete();
                        document.getElementById("spinnerMessage").textContent = null;
                        document.getElementById("spinnerMessage").classList.remove("fade-in");
                    }, 3000);
                } else {
                    this.spinner.hide();
                    observer.next();
                    observer.complete();
                }
            }, 1500);
        });
    }
}
