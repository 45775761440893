import { Observable } from 'rxjs';
import { Settings } from './settings';
import { UsuarioPerfil } from 'app/shared/enums/usuario-perfil.enum';

export interface User {
  id: number;
  role: string;
  email: string;
  nome?: string;
  login: string;
  picture: string;
  settings: Settings;
  perfil: UsuarioPerfil;
}

export abstract class UserData {
  abstract getCurrentUser(): Observable<User>;
  abstract list(pageNumber: number, pageSize: number): Observable<User[]>;
  abstract get(id: number): Observable<User>;
  abstract update(user: User): Observable<User>;
  abstract updateCurrent(user: User): Observable<User>;
  abstract create(user: User): Observable<User>;
  abstract delete(id: number): Observable<boolean>;
}
