import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ngx-ok.dialog',
    templateUrl: './ok.dialog.component.html',
    styleUrls: ['./ok.dialog.component.scss']
})
export class OKDialogComponent implements OnInit {

    @Input()
    titulo: string;

    @Input()
    mensagem: string;

    @Input()
    okCallback;

    constructor() { }

    ngOnInit(): void {
    }

    okClicked() {
        if (this.okCallback) {
            this.okCallback();
        }

    }
}
