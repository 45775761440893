export const ball = '<svg height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" stroke-width="3" fill="red" /></svg>';

export const square = '<svg width="15" height="15"><rect x="0" y="0" width="15" height="15" style="fill:blue;stroke:pink;stroke-width:5;fill-opacity:1;stroke-opacity:0.9" /></svg>';

export const home = '<svg xmlns="http://www.w3.org/2000/svg" width="20.663" height="20.464" viewBox="0 0 20.663 14.464"><path id="home" d="M2,5V19.464H22.663V5H2m18.6,7.232H16.464V7.066H20.6v5.166m-6.2-2.066H10.265v-3.1H14.4v3.1m-4.133,2.066H14.4V17.4H10.265V12.232m-6.2-5.166H8.2V17.4H4.066V7.066M16.464,17.4V14.3H20.6v3.1Z" transform="translate(-2 -5)" fill="#fff"/></svg>';

export const investiga = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path id="investiga" d="M14,2A12,12,0,1,0,26,14a11.728,11.728,0,0,0-.732-4.068l-1.92,1.92A9.3,9.3,0,0,1,23.6,14,9.6,9.6,0,1,1,14,4.4a9.3,9.3,0,0,1,2.148.252L18.08,2.72A12.123,12.123,0,0,0,14,2m8.4,0L17.6,6.8V8.6l-3.06,3.06A1.661,1.661,0,0,0,14,11.6,2.4,2.4,0,1,0,16.4,14a1.661,1.661,0,0,0-.06-.54L19.4,10.4h1.8L26,5.6H22.4V2M14,6.8A7.2,7.2,0,1,0,21.2,14H18.8A4.8,4.8,0,1,1,14,9.2Z" transform="translate(-2 -2)" fill="#fff"/></svg>';

export const pesquisa = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 20"><path id="pesquisa" d="M22,4a2,2,0,0,1,2,2V16a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h6l2,2h8M2,6V20H20v2H2a2,2,0,0,1-2-2V11H0V6H2M6,6V16H22V6Z" transform="translate(0 -2)" fill="#fff"/></svg>';

export const cliente = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 21.915 19.923"><g id="cliente" transform="translate(-743 541)"><path id="monitor-dashboard" d="M20.923,15.946V3.992H2.992V15.946h17.93M20.923,2a1.992,1.992,0,0,1,1.992,1.992V15.946a1.992,1.992,0,0,1-1.992,1.992H13.95V19.93h1.992v1.992H7.973V19.93H9.965V17.938H2.992A1.992,1.992,0,0,1,1,15.946V3.992A1.985,1.985,0,0,1,2.992,2h17.93" transform="translate(742 -543)" fill="#fff"/><path id="account" d="M8.8,4.342a2.4,2.4,0,1,1-2.4,2.4,2.4,2.4,0,0,1,2.4-2.4m0,5.565c2.65,0,4.8,1.073,4.8,2.4v1.2H4V12.3C4,10.98,6.147,9.906,8.8,9.906Z" transform="translate(744.889 -541.883)" fill="#fff"/></g></svg>';

export const usuario = '<svg xmlns="http://www.w3.org/2000/svg" width="19.76" height="20.989" viewBox="0 0 19.76 22.989"><path id="usuario" d="M8.379,12.947h8.758v2.189H8.379V12.947m2.189,8.758H6.189V4.189h7.663V9.663h5.474v3.394l2.189-2.189v-2.3L14.947,2H6.189A2.189,2.189,0,0,0,4,4.189V21.705a2.189,2.189,0,0,0,2.189,2.189h4.379V21.705M8.379,19.516h4.488l.985-.985v-1.2H8.379v2.189m13.356-5.474a.83.83,0,0,1,.438.219L23.6,15.684a.666.666,0,0,1,0,.876L22.5,17.655l-2.3-2.3L21.3,14.261a.569.569,0,0,1,.438-.219m0,4.269-6.678,6.678h-2.3v-2.3l6.678-6.678Z" transform="translate(-4 -2)" fill="#fff"/></svg>';

export const status = '<svg xmlns="http://www.w3.org/2000/svg" width="17.471" height="19.206" viewBox="0 0 17.471 19.206"><path id="status" d="M-2435.28,1481.927h3.768l2.055-6.509,4.453,13.36,3.426-6.851h3.768" transform="translate(2435.28 -1472.179)" fill="none" stroke="#fff" stroke-width="2"/></svg>';

export const config = '<svg xmlns="http://www.w3.org/2000/svg" width="20.123" height="20.688" viewBox="0 0 20.123 20.688"><path id="config" d="M20.02,13.347,22.2,15.064a.522.522,0,0,1,.124.662l-2.069,3.579a.52.52,0,0,1-.631.228l-2.576-1.045A7.532,7.532,0,0,1,15.3,19.512l-.383,2.741a.523.523,0,0,1-.517.434H10.266a.523.523,0,0,1-.517-.434l-.383-2.741a7.184,7.184,0,0,1-1.748-1.024L5.042,19.533a.52.52,0,0,1-.631-.228L2.342,15.726a.51.51,0,0,1,.124-.662l2.183-1.717-.072-1,.072-1.034L2.467,9.623a.51.51,0,0,1-.124-.662L4.411,5.382a.508.508,0,0,1,.631-.228L7.618,6.189A7.5,7.5,0,0,1,9.366,5.176l.383-2.741A.523.523,0,0,1,10.266,2H14.4a.523.523,0,0,1,.517.434L15.3,5.176a7.5,7.5,0,0,1,1.748,1.014l2.576-1.034a.508.508,0,0,1,.631.228l2.069,3.579a.522.522,0,0,1-.124.662L20.02,11.309l.072,1.034-.072,1m-13.374-1A5.9,5.9,0,0,0,6.9,14.061L4.763,15.819l.776,1.345,2.607-.972A5.67,5.67,0,0,0,11.093,17.9l.455,2.72H13.1l.455-2.72a5.684,5.684,0,0,0,2.969-1.7l2.6.972L19.9,15.83l-2.141-1.758a5.64,5.64,0,0,0,.269-1.727,5.827,5.827,0,0,0-.259-1.707L19.9,8.889,19.12,7.544l-2.586.962a5.716,5.716,0,0,0-2.969-1.717l-.455-2.72H11.559L11.1,6.789A5.716,5.716,0,0,0,8.135,8.506L5.549,7.534,4.773,8.879,6.9,10.637a5.827,5.827,0,0,0-.259,1.707m5.689-3.62a3.62,3.62,0,1,1-3.62,3.62,3.62,3.62,0,0,1,3.62-3.62m0,2.069a1.552,1.552,0,1,0,1.552,1.552A1.552,1.552,0,0,0,12.334,10.792Z" transform="translate(-2.271 -2)" fill="#fff"/></svg>';

export const fonteGrupo = '<svg xmlns="http://www.w3.org/2000/svg" width="20.123" height="20.688" viewBox="0 0 20.123 20.688"><path id="config" d="M18 5V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v1a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3zM8 4h8v4H8V4zm11 15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7a1 1 0 0 1 1 1z" transform="translate(-2.271 -2)" fill="#fff"/></svg>';

