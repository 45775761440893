
<nb-card style="width: 800px;">
    <nb-card-header>{{ titulo }}</nb-card-header>
    <nb-card-body>
        {{ mensagem }}
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col-lg-8"></div>
            <div class="col-lg-2">
                <button (click)="simClicked()" nbButton status='success' fullWidth>Sim</button>
            </div>
            <div class="col-lg-2">
                <button (click)="naoClicked()" nbButton fullWidth>Não</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>
