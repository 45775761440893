<div class="text-center mb-5" style="margin-top: -45px;">
    <h2 class="text-white">BACKOFFICE</h2>
</div>
<div [formGroup]="loginForm" aria-labelledby="title">
    <div class="form-control-group">
        <input class="input-user"  nbInput fullWidth id="input-email" formControlName="email" [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''" [attr.aria-invalid]="email.invalid && email.dirty ? true : null">        
    </div>

    <div class="form-control-group">
        
           
        
        <input class="input-password" nbInput fullWidth id="input-password" formControlName="password" type="password" [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''">       
        <a class="forgot-password text-white" routerLink="../recuperacao-senha">Esqueceu sua senha?</a>
    </div>
    
    <button nbButton fullWidth status="primary" size="large" (click)="login()" [disabled]="!loginForm.valid" style="background-color:#00ccff !important;">
        Entrar
    </button>
</div>