import { UiService } from '../../../shared/services/ui.service';
import { AuthService } from '../../../shared/services/http/auth.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import { InitUserService } from '../../../@theme/services/init-user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LoginPage implements OnInit {

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  loginForm: FormGroup;
  alive: boolean = true;

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  constructor(
    protected authService : AuthService,
    protected cd: ChangeDetectorRef,
    protected themeService: NbThemeService,
    private fb: FormBuilder,
    protected router: Router,
    private ui: UiService,
    protected initUserService: InitUserService) { }

  ngOnInit(): void {   
      this.loginForm = this.fb.group({
        email: this.fb.control('vivian@rangeinvestigacao.com', [Validators.required]),
        password: this.fb.control('', [Validators.required]),
        rememberMe: this.fb.control(false),
      });
  }

  login(): void {
    if(!this.loginForm.valid) return;

    this.user = this.loginForm.value;
    this.errors = [];
    this.messages = [];

    let okFn = (data) => {
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('refreshToken', data.token.refresh_token);
        this.initUserService.initCurrentUser().subscribe(() => {
            this.router.navigate(['']);
        });        
    };

    let nokFn = (data) => {
        this.ui.error("Usuário e/ou senha inválidos.");
    };

    this.authService.login(this.user).subscribe(okFn, nokFn);
  }
}
