<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <img src="https://portal.credvalue.com.br/assets/img/logo-credvalue-v2.svg" width="180px"/>
    </a>
    <!-- <img alt="range 2C.png" src="https://static.wixstatic.com/media/b83e3e_6a8212fe1a934d2e954ae16cfa786960~mv2.png/v1/fill/w_276,h_54,al_c,q_85,usm_0.66_1.00_0.01/range%202C.webp" style="width: 276px; height: 54px; object-fit: cover;" /> -->
   <!-- <a class="logo" href="#" (click)="navigateHome()"><span>rangeinvestigacao.com</span></a> -->
  </div>
  <!-- 
      <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
-->
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> 
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    -->
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.nome"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
