import { UsuarioService } from 'app/shared/services/http/usuario.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'app/shared/services/ui.service';

@Component({
    selector: 'ngx-reset-password-page',
    styleUrls: ['./alteracao-senha.page.scss'],
    templateUrl: './alteracao-senha.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlteracaoSenhaPage implements OnInit {

    errors: string[] = [];
    messages: string[] = [];
    user: any = {};
    resetPasswordForm: FormGroup;

    constructor(
        protected cd: ChangeDetectorRef,
        protected fb: FormBuilder,
        protected usuarioService: UsuarioService,
        protected ui: UiService,
        protected loading: UiService,
        protected router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {          
        this.resetPasswordForm = this.fb.group({
            password: this.fb.control('', [Validators.required]),
            confirmPassword: this.fb.control('', [Validators.required]),
        });
        this.ui.loaded().subscribe();
    }

    get password() { return this.resetPasswordForm.get('password'); }
    get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }

    resetPass(): void {
        this.errors = this.messages = [];
        this.user = this.resetPasswordForm.value;

        this.route.queryParams.subscribe(params => {
            if (params['token']) {
                let request = this.resetPasswordForm.value;
                request.token = params['token'];

                let okFn = (data) => {
                    this.loading.loaded().subscribe(() => {
                        this.router.navigate(['auth/login']);
                    });                    
                };
                let nokFn = (data) => {
            
                };
                this.loading.loading();
                this.usuarioService.finalizarRecuperacaoSenha(request).subscribe(okFn, nokFn);
            } else {
                let okFn = (data) => {
                    this.loading.loaded().subscribe(() => {
                        this.router.navigate(['']);
                    });                      
                };
                let nokFn = (data) => {
            
                };
                this.loading.loading();
                this.usuarioService.alterarSenha(this.user).subscribe(okFn, nokFn);
            }
        });
    }
}
