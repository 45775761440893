<h4 id="title" class="title text-white">Alteração de Senha</h4>

<div [formGroup]="resetPasswordForm" aria-labelledby="title">

  <div class="form-control-group">
    <label for="input-password" class="text-white">Senha:</label>
    <input class="input-password first" formControlName="password" [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" nbInput autofocus fullWidth id="input-password"  type="password" />
  </div>

  <div class="form-control-group">
    <label for="input-re-password" class="text-white">Confirmação de Senha:</label>
    <input class="input-password" formControlName="confirmPassword" [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''" nbInput fullWidth id="input-re-password"  type="password" />
    <p class="error-message caption status-danger" *ngIf="password.value != confirmPassword.value && confirmPassword.dirty">
      Senhas não conferem!
    </p>
  </div>

  <button (click)="resetPass()" nbButton status="primary" fullWidth [disabled]="!resetPasswordForm.valid || password.value != confirmPassword.value" style="background-color:#00ccff !important;">
    Alterar Senha
  </button>
</div>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link text-white" routerLink="../login">Voltar ao Log In</a></p>  
</section>
