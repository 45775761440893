import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NgxAuthComponent,
  LoginPage,
  LogoutPage,
  RecuperacaoSenhaPage,
  AlteracaoSenhaPage,
} from './components';

const routes: Routes = [{
  path: '',
  component: NgxAuthComponent,
  children: [
    {
      path: '',
      component: LoginPage,
    },
    {
      path: 'login',
      component: LoginPage,
    }, 
    {
      path: 'logout',
      component: LogoutPage,
    },
    {
      path: 'recuperacao-senha',
      component: RecuperacaoSenhaPage,
    },
    {
      path: 'alteracao-senha',
      component: AlteracaoSenhaPage,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
