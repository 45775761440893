import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';


import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ngx-auth',
  styleUrls: ['./auth.component.scss'],
  template: `
    <nb-layout>
      <nb-layout-column class="bg-login">
        <nb-card>
          <nb-card-header>
            <img src="https://portal.credvalue.com.br/assets/img/logo-credvalue-v2.svg" class="icons-top"/>
          </nb-card-header>
          <nb-card-body>
            <div class="auth-block">
              <router-outlet></router-outlet>
            </div>
          </nb-card-body>
        </nb-card>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class NgxAuthComponent implements OnDestroy {

  private alive = true;

  subscription: any;

  authenticated: boolean = false;
  token: string = '';

  // showcase of how to use the onAuthenticationChange method
  constructor(protected location: Location) {

    // this.subscription = auth.onAuthenticationChange()
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe((authenticated: boolean) => {
    //     this.authenticated = authenticated;
    //   });
  }

  back() {
    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
