import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
@Directive({
  selector: '[ngxFormatoMesAno]'
})
export class FormatoMesAnoDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (value.length > 2) {
      value = value.substring(0, 2) + '/' + value.substring(2, 6); // Adiciona a barra após os dois primeiros dígitos
    }
    this.renderer.setProperty(input, 'value', value);
  }


}
