import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './@auth/auth.module';

import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbCardModule,
  NbToastrModule,
  NbWindowModule,
  NbButtonModule,
} from '@nebular/theme';
import { NgxSpinnerModule } from 'ngx-spinner';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ConfirmacaoDialogComponent } from './shared/components/confirmacao.dialog/confirmacao.dialog.component';
import { OKDialogComponent } from './shared/components/ok.dialog/ok.dialog.component';
import { CodeEditorModule } from '@ngstack/code-editor';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [AppComponent, ConfirmacaoDialogComponent, OKDialogComponent ],
  exports: [],
  imports: [    
    BrowserModule,
    BrowserAnimationsModule,
    NbCardModule,
    NbButtonModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),    
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NgxSpinnerModule,
    CodeEditorModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
})
export class AppModule {
}
